<script>
import SynopticEquipmentDataControl from "@/components/synoptic/synoptic-equipment-data-control.vue";

export default {
  name: "SynopticEquipmentDataControlBase",
  extends: SynopticEquipmentDataControl,
  computed: {
    formatList() {
      return this.$root.config.references.data_value_format_types || [];
    },
    textList() {
      if (!this?.lastData?.text_list?.id) return null;
      return (this.$root.config.references.text_lists || []).find(
        ({id}) => id == this.lastData.text_list.id || ""
      );
    },
    dataFormat() {
      if (!this.lastData) return null;
      return this.formatList.find(
        ({id}) => id == this.lastData?.value_format_type?.id || ""
      );
    },
    mode() {
      return this.$store.getters["dashboard/mode"] || "viewer";
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = Object.assign({}, this.control.synopticComponent.style, {});
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return {
        ...style,
        "border-radius": style["border-radius"] ?? "3px",
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`
      };
    },
    timestamp() {
      return this?.lastData?.current_value?.date_time || "";
    }
  },
  watch: {
    timestamp: {
      handler(n, o) {
        if (n == o) return;
        this.dataChange();
      },
      immediate: true
    },
    lastData: {
      handler(n) {
        if (n) {
          // console.log(n?.current_value?.value);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  methods: {
    equipmentData(id) {
      return (
        (this.$store.getters["dashboard/dataList"] || []).find(
          (i) => i.id == id
        ) || null
      );
    },
    dataChange() {
      let text = [];
      let ts = this?.lastData?.type
        ? this?.lastData?.current_value?.date_time || ""
        : "";
      ts = ts ? this.$dt.format(ts) : "";
      let vlr = this?.lastData?.current_value
        ? this?.lastData?.current_value.value
        : "";
      if (vlr !== "" && !isNaN(Number(vlr)) && this.control.format) {
        let exp = this.expression || "";
        if (
          this?.dataFormat?.format_mask == "duration" &&
          this.control.format.indexOf("%") == -1 &&
          !/Date/.test(exp)
        ) {
          vlr = moment
            .duration(parseInt(vlr), this.lastData.unity_label)
            .format(this.control.format || undefined, {trim: false});
        } else {
          vlr = this.$utils.sprintf(this.control.format, vlr);
        }
      }

      let value = ts ? `${vlr} - ${ts}` : vlr;

      // Preenche o texto que aparece no title do componente
      if (this?.control?.synopticComponent.hint) {
        text.push(this?.control?.synopticComponent.hint);
      }

      text.push(
        `${this.$tc("data", 1)}: (${this?.lastData?.id}) ${
          this?.lastData?.name
        }`
      );
      text.push(
        `${this.$tc("device", 1)}: (${this?.lastData?.device?.id}) ${
          this?.lastData?.device?.name
        }`
      );
      if (this?.lastData?.memory_type)
        text.push(
          `${this.$t("memory_type")}: ${this?.lastData?.memory_type?.name}`
        );
      text.push(`${this.$t("value")}: ${value}`);
      if (this?.lastData?.identity_embedded_app)
        text.push(
          `${this.$t("identity_embedded_app")}: ${
            this.lastData.identity_embedded_app || ""
          }`
        );

      // Preenche as informações de erro e desabilitação
      if (this.error) {
        text.push(this.$t(this.error));
      }
      if (this.lastData && !this.lastData.enabled) {
        text.push(this.$tc("disabled"));
      }
      this.$emit("dataChange", text.join("\n"));
    }
  }
};
</script>
