<template>
  <section>
    <TogglePanel :title="$t('area')">
      <InlineDimensionForm
        title="padding"
        :lockbles="[0, 1, 2, 3]"
        :labels="['top', 'right', 'left', 'bottom']"
        v-model="gridPadding"
      />
    </TogglePanel>
    <TogglePanel :title="$t('controls')">
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="showToolbar" />
          {{ $t("toolbar") }}
          <Tooltip :title="$t('hints.toolbar')" />
        </label>
      </div>
      <!-- begin zoom -->
      <div class="checkbox">
        <label>
          <input type="checkbox" v-model="enabledChartZoom" />
          {{ $t("enable_zoom") }}
          <Tooltip :title="$t('hints.chart_zoom')" />
        </label>
        <div class="checkbox" style="margin-top: 5px">
          <label>
            <input type="checkbox" v-model="enablePanelZoom" />
            {{ $tc("panel", 1) }}
          </label>
        </div>
        <div class="checkbox" style="margin-bottom: 15px">
          <label>
            <input type="checkbox" v-model="enableChartAreaZoom" />
            {{ $t("area_zoom") }}
          </label>
        </div>
      </div>
    </TogglePanel>
    <!-- end zoom -->
  </section>
</template>

<script>
import {isEqual} from "lodash";
import Tooltip from "@/components/tooltip.vue";
import InlineDimensionForm from "@/components/synoptic/property-editor/controls/inline-dimension-form";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
export default {
  name: "ChartGeneralForm",
  components: {
    Tooltip,
    InlineDimensionForm,
    TogglePanel
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: null
    }
  },
  data: () => ({
    chartOptions: {}
  }),
  computed: {
    gridPadding: {
      set(value) {
        let rect = {
          top: value[0] || 0,
          right: value[1] || 0,
          left: value[2] || 0,
          bottom: value[3] || 0
        };
        this.$set(this.chartOptions, "grid", {
          ...this.chartOptions.grid,
          ...rect
        });
      },
      get() {
        return [
          this?.chartOptions?.grid?.top || 0,
          this?.chartOptions?.grid?.right || 0,
          this?.chartOptions?.grid?.left || 0,
          this?.chartOptions?.grid?.bottom || 0
        ];
      }
    },
    showToolbar: {
      set(value) {
        let toolbox = {
          show: true,
          orient: "vertical",
          top: 30,
          feature: {
            restore: {},
            dataZoom: {
              title: {
                zoom: this.$t("area_zoom"),
                back: this.$t("back")
              },
              emphasis: {
                iconStyle: {
                  textFill: "#fff",
                  textBackgroundColor: "rgba(50,50,50,0.7)",
                  textPadding: 5,
                  textPosition: "left"
                }
              }
            }
          }
        };
        toolbox.show = value;
        this.$set(this?.chartOptions, "toolbox", toolbox);
        // adjust grid padding bottom if needed
        let p = this.gridPadding;
        let r = p[1]; // right
        // r += value ? 20 : -20;
        this.gridPadding = [p[0], r, p[2], p[3]];
      },
      get() {
        return this?.chartOptions?.toolbox?.show || false;
      }
    },
    enabledChartZoom: {
      set(value) {
        this.enablePanelZoom = value;
        this.enableChartAreaZoom = value;
      },
      get() {
        return this.enablePanelZoom || this.enableChartAreaZoom;
      }
    },
    enablePanelZoom: {
      set(value) {
        let lst = JSON.parse(
          JSON.stringify(this?.chartOptions?.dataZoom || [])
        );
        let i = lst.findIndex(({type}) => type == "slider");
        i = i >= 0 ? i : lst.length;
        lst[i] = {
          type: "slider",
          show: value,
          labelFormatter: null
        };
        this.$set(this?.chartOptions, "dataZoom", lst);
        // adjust grid padding bottom if needed
        let p = this.gridPadding;
        let b = p[3]; // bottom
        // b += value && b <= 10 ? 30 : !value && b >= 40 ? -30 : 0;
        b += value ? 40 : -40;
        this.gridPadding = [p[0], p[1], p[2], b];
      },
      get() {
        let lst = this?.chartOptions?.dataZoom || [];
        let item = lst.find(({type}) => type == "slider") || {};
        return (item && item?.show) || false;
      }
    },
    enableChartAreaZoom: {
      set(value) {
        let lst = JSON.parse(
          JSON.stringify(this?.chartOptions?.dataZoom || [])
        );
        let i = lst.findIndex(({type}) => type == "inside");
        i = i >= 0 ? i : lst.length;
        lst[i] = {
          type: "inside",
          disabled: !value
        };
        this.$set(this?.chartOptions, "dataZoom", lst);
      },
      get() {
        let lst = this?.chartOptions?.dataZoom || [];
        let item = lst.find(({type}) => type == "inside") || {};
        return !((item && item?.disabled) || false);
      }
    }
  },
  watch: {
    value: {
      handler(n) {
        if (!n || isEqual(n, this.chartOptions)) return;
        this.$set(this, "chartOptions", JSON.parse(JSON.stringify(n)));
      },
      deep: true,
      immediate: true
    },
    chartOptions: {
      handler(n) {
        this.$nextTick(() => {
          if (isEqual(n, this.value)) return;
          this.$emit("input", n);
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped>
div.checkbox > div.checkbox > label {
  margin-left: 20px;
}
</style>
