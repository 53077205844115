<template>
  <div>
    <!-- begin named query selection -->
    <div style="margin-bottom: 10px; border-bottom: 1px solid lightgray">
      <div class="form-group form-group-sm">
        <div>
          <label for="">{{ $t("source") }}</label>
        </div>
        <label class="radio-inline" for="namedQueryDisabled">
          <input
            type="radio"
            name="namedQueryEnabled"
            :checked="namedQueryEnabled === false"
            :value="false"
            @input="namedQueryEnabled = false"
            id="namedQueryDisabled"
          />{{ $t("data_list") }}
        </label>
        <label class="radio-inline" for="namedQueryEnabled">
          <input
            type="radio"
            name="namedQueryEnabled"
            :checked="namedQueryEnabled === true"
            :value="true"
            @input="namedQueryEnabled = true"
            id="namedQueryEnabled"
          />{{ $tc("titles.named_query", 1) }}
        </label>
      </div>
    </div>
    <template v-if="namedQueryEnabled">
      <div class="form-group form-group-sm" style="margin-bottom: 25px">
        <select v-model="namedQuery" class="form-control">
          <option value="">{{ $t("select") }}</option>
          <option
            v-for="item in namedQueryList"
            :value="item.name"
            :key="item.name"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </template>
    <!-- end named query selection -->
  </div>
</template>

<script>
export default {
  name: "DataSetSourceSelector",
  data() {
    return {
      iNamedQueryEnabled: false
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"];
    },
    namedQueryList() {
      return this?.template?.namedQueries || [];
    },
    namedQuery: {
      set(value) {
        this.$set(this.panel.options, "namedQuery", value);
        this.apply();
      },
      get() {
        return this?.panel?.options?.namedQuery || "";
      }
    },
    namedQueryEnabled: {
      set(value) {
        this.iNamedQueryEnabled = value;
        if (this.iNamedQueryEnabled) {
          this.apply();
        } else {
          this.namedQuery = "";
        }
      },
      get() {
        return this.iNamedQueryEnabled;
      }
    },
    selected() {
      return (
        (this.namedQuery &&
          this.namedQueryList.find(({name}) => this.namedQuery == name)) ||
        null
      );
    }
  },
  methods: {
    save() {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        panel: this.panel,
        screenId: this.draft.screenId
      });
    },
    apply() {
      this.$emit("apply", this.selected);
    }
  },
  created() {
    if (this.namedQuery) this.iNamedQueryEnabled = true;
  }
};
</script>

<style scoped>
</style>